import React, {useEffect, useState} from "react";
import {Chart, registerInteraction} from '@antv/g2';
import {Options} from "@antv/g2plot";
import {Button, Select, Spin} from "antd"
import 'antd/dist/antd.css';
import "../../style/SelectTSOStyle.css"
import {
    annotationForLiveDemoRegion,
    annotationForLiveDemoText,
    entsoeErrorTheme,
    graph1Region,
    graph2Region,
    graph3Region,
    graphHeight, pointsOfPartData,
} from "../../utils/GraphsStyle";
import {getTSOList} from "../../service/TSOService";
import {EnergyData, GraphData, locales, TransferSystemOperation} from "../../entity/Entity";
import {getHolidays} from "../../service/HolidayService";
import {Annotation} from "@antv/g2plot/lib/types/annotation";
// eslint-disable-next-line
import {log} from "@bokeh/bokehjs/build/js/types/core/util/math";
import {getDataByTso} from "../../service/DataService";
import MapeTable from "../mape";
// @ts-ignore
import SockJsClient from 'react-stomp';
import {ROOT_URL} from "../../api/ApiUtil";
import {Option} from "antd/es/mentions";
import {translationsObject} from "../../locale";

const InsightGraph = (): JSX.Element => {
    const [tsoList, setTsoList] = useState<TransferSystemOperation[]>([]);
    const [holidaysForGraph, setHolidaysForGraph] = useState<Annotation[]>([]);
    const [originData, setOriginData] = useState<EnergyData[]>([]);
    const [graphData, setGraphData] = useState<GraphData[]>([]);
    const [chart, setChart] = useState<Chart | undefined>(undefined);
    const [tsoPlaceHolder, setTsoPlaceHolder] = useState<string>("");
    const [selectedTso, setSelectedTso] = useState<TransferSystemOperation>();
    const [locale, setLocale] = useState<typeof locales[number]>("en");
    const [colorMap, setColorMap] = useState<Map<string, string>>(new Map());
    const [showGraphSpin, setShowGraphSpin] = useState<boolean>(true);
    const [showMapeSpin, setShowMapeSpin] = useState<boolean>(true);
    const [latestActualDate, setLatestActualDate] = useState<string>("");
    const [maxLimitAct, setMaxLimitAct] = useState<number>(0);
    const [minLimitAct, setMinLimitAct] = useState<number>(0);
    const [maxLimitError, setMaxLimitError] = useState<number>(0);
    const [isPartDayData, setIsPartDayData] = useState<boolean>(true);

    const createGraph = () => {
        registerInteraction('other-filter', {
            showEnable: [
                {trigger: 'plot:mouseenter', action: 'cursor:crosshair'},
                {trigger: 'mask:mouseenter', action: 'cursor:move'},
                {trigger: 'plot:mouseleave', action: 'cursor:default'},
                {trigger: 'mask:mouseleave', action: 'cursor:crosshair'},
            ],
            start: [
                {
                    trigger: 'plot:mousedown', isEnable(context) {
                        return !context.isInShape('mask');
                    }, action: ['x-rect-mask:start', 'x-rect-mask:show']
                },
                {trigger: 'mask:dragstart', action: 'x-rect-mask:moveStart'}
            ],
            processing: [
                {trigger: 'plot:mousemove', action: 'x-rect-mask:resize'},
                {trigger: 'mask:drag', action: 'x-rect-mask:move'},
                {trigger: 'mask:change', action: 'sibling-x-filter:filter'}
            ],
            end: [
                {trigger: 'plot:mouseup', action: 'x-rect-mask:end'},
                {trigger: 'mask:dragend', action: 'x-rect-mask:moveEnd'}
            ],
            rollback: [
                {trigger: 'dblclick', action: ['x-rect-mask:hide', 'sibling-x-filter:reset']}
            ]
        });

        const localChart = new Chart({
            container: 'container',
            autoFit: true,
            height: graphHeight,
            defaultInteractions: []
        });

        localChart.scale({
            date: {
                tickCount: 5,
                range: [0, 1]
            }
        });

        localChart.interaction('legend-filter', {
            start: [
                {trigger: 'legend-item-name:click', action: ['list-unchecked:toggle', 'data-filter:filter']},
                {trigger: 'legend-item-marker:click', action: ['list-checked:checked', 'data-filter:filter']},
            ]
        });

        localChart.tooltip({
            showCrosshairs: true,
            shared: true,
        });

        localChart.legend('type1', {
            offsetY: graph1Region.region.end.y * graphHeight,
            position: "top"
        });

        localChart.legend('type2', {
            offsetY: graph3Region.region.end.y * graphHeight - graph3Region.padding[2],
            position: "top",
            selected: {
                'TSO error, (%)': true,
                '24Insight error, (%)': true
            }
        });

        const annotations = [...holidaysForGraph, annotationForLiveDemoRegion(latestActualDate), annotationForLiveDemoText(latestActualDate, locale, isPartDayData)];

        const option: Options = {
            data: graphData,
            padding: 'auto',
            xAxis: {
                tickCount: 10,
            },
            annotations: annotations,
        };

        const view1 = localChart.createView(graph1Region);

        view1.legend(true);
        view1.scale("value1", {
            nice: true,
            max: maxLimitAct,
            min: minLimitAct
        });

        view1.axis('entsoeAct', {
            title: {
                text: translationsObject[locale].total_load
            }
        });

        view1.axis('value1', {
            title: {
                text: translationsObject[locale].total_load
            }
        });
        view1.axis('date', {
            line: {
                style: {
                    stroke: '#000000',
                }
            },
            tickLine: {
                style: {
                    stroke: '#000000',
                }
            }
        });
        view1.animate(false);
        view1.data(graphData);
        view1.interaction('tooltip');
        view1.interaction('sibling-tooltip');
        // @ts-ignore
        view1.updateOptions(option);
        view1.line().position('date*value1')
            .color('type1', (val: string): any => {
                return colorMap.get(val);
            })
            .style('type1', (value: string): any => {
                if (value === translationsObject[locale].insight_predicate_total_load_line
                    || value === translationsObject[locale].entsoe_predicate_load_line) {
                    return {
                        lineDash: [3, 3]
                    };
                }
                return value;
            });

        const view2 = localChart.createView(graph3Region);
        view2.data(graphData);
        view2.animate(false);
        view2.axis(true);
        view2.scale("value2", {
            nice: true,
            max: maxLimitError
        });
        view2.axis('value2', {
            title: {
                text: translationsObject[locale].ape_error
            }
        });
        view2.axis('date', {
            line: {
                style: {
                    stroke: '#000000',
                }
            },
            tickLine: {
                style: {
                    stroke: '#000000',
                }
            }
        });
        // @ts-ignore
        view2.updateOptions(option);
        view2.interaction('tooltip');
        view2.interaction('sibling-tooltip');
        view2.line(entsoeErrorTheme).position('date*value2').color('type2',
            (val: string): any => {
                return colorMap.get(val);
            });

        const view3 = localChart.createView(graph2Region);

        view3.interaction('other-filter');
        view3.tooltip(false);
        view3.axis(true);
        view3.animate(false);
        view3.scale("value1", {
            nice: true,
            max: maxLimitAct,
            min: minLimitAct
        });
        // @ts-ignore
        view3.updateOptions(option);
        view3.area().position('date*value1')
            .color('type1', (val: string): any => {
                return '#8e8d8d';
            });

        localChart.render();

        console.debug("render chart");

        setShowGraphSpin(false);
        if (chart !== undefined) {
            chart.destroy();
        }

        setChart(localChart);
    };

    const onSelectTSO = (value: number) => {
        setShowGraphSpin(true);
        setShowMapeSpin(true);

        const selectedTso = tsoList.find((tso) => tso.id === value);
        setSelectedTso(selectedTso);

        if (selectedTso === undefined) {
            console.error("Can't found TSO for TSO id = " + value);
            return;
        }

        const selectedCountry = selectedTso.country;

        getHolidays(selectedCountry, setHolidaysForGraph);

        getDataByTso(selectedTso, setOriginData);

        setTsoPlaceHolder(`${selectedTso.country.name} - ${selectedTso.name}`)
    };

    const onIncomeMessage = (msg: any) => {
        console.debug("Income new data from socket");
        setOriginData(msg.data);
        chart!.render();
    };

    const changeLocale = (value: typeof locales[number]) => {
        setLocale(value);
    }

    const generateColorMap = () => {
        const colorMap = new Map();

        // Actual graph
        colorMap.set(translationsObject[locale].actual_total_load_line, '#000000');
        colorMap.set(translationsObject[locale].entsoe_predicate_load_line, '#bdbbbb');
        colorMap.set(translationsObject[locale].insight_predicate_total_load_line, '#0e4ff1');
        // Errors graph
        colorMap.set(translationsObject[locale].entsoe_error, '#bdbbbb');
        colorMap.set(translationsObject[locale].insight_error, '#0e4ff1');

        setColorMap(colorMap);
    };

    useEffect(() => {
        generateColorMap();
        getTSOList(setTsoList);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (tsoList.length > 0) {
            onSelectTSO(tsoList[0].id);
        }
        // eslint-disable-next-line
    }, [tsoList]);

    useEffect(() => {
        console.debug("Start prepare data for graph");

        let newMaxLimitAct = 0;
        let newMinLimitAct = Number.MAX_VALUE;
        let newMaxLimitError = 0;

        if (originData.length > 0) {
            let data: GraphData[] = [];

            originData.forEach((value: EnergyData, index: number) => {
                //get latest actual date.
                if (!isPartDayData || (isPartDayData && index > originData.length - pointsOfPartData)) {
                    // max act
                    if (value.entsoeAct > newMaxLimitAct) {
                        newMaxLimitAct = value.entsoeAct;
                    }
                    if (value.entsoeForecast > newMaxLimitAct) {
                        newMaxLimitAct = value.entsoeForecast;
                    }
                    if (value.insightForecast > newMaxLimitAct) {
                        newMaxLimitAct = value.insightForecast;
                    }

                    // min act
                    if (value.entsoeAct < newMinLimitAct && value.entsoeAct !== null && value.entsoeAct > 0) {
                        newMinLimitAct = value.entsoeAct;
                    }
                    if (value.entsoeForecast < newMinLimitAct && value.entsoeForecast !== null && value.entsoeForecast > 0) {
                        newMinLimitAct = value.entsoeForecast;
                    }
                    if (value.insightForecast < newMinLimitAct && value.insightForecast !== null && value.insightForecast > 0) {
                        newMinLimitAct = value.insightForecast;
                    }

                    // error
                    if (value.entsoeError > newMaxLimitError) {
                        newMaxLimitError = value.entsoeError;
                    }
                    if (value.insightError > newMaxLimitError) {
                        newMaxLimitError = value.insightError;
                    }
                }

                const entsoeAct: GraphData = {
                    date: value.date,
                    type1: translationsObject[locale].actual_total_load_line,
                    value1: value.entsoeAct,
                    type2: translationsObject[locale].insight_error,
                    value2: value.insightError
                };

                const entsoeForecast: GraphData = {
                    date: value.date,
                    type1: translationsObject[locale].entsoe_predicate_load_line,
                    value1: value.entsoeForecast,
                    type2: translationsObject[locale].entsoe_error,
                    value2: value.entsoeError

                }

                const insightForecast: GraphData = {
                    date: value.date,
                    type1: translationsObject[locale].insight_predicate_total_load_line,
                    value1: value.insightForecast,
                }

                if (!isPartDayData || (isPartDayData && index > originData.length - pointsOfPartData)) {
                    data.push(entsoeForecast);
                    data.push(insightForecast);
                    data.push(entsoeAct);
                }
            });

            console.debug("Converted data to graphData with length ", data.length);
            console.debug("Converted data to graphData with points ", data.length / 3);

            const startLiveDemoDate = originData[originData.length - 96].date;
            console.debug("Live demo start date: ", startLiveDemoDate);

            console.debug("Max limit act ", newMaxLimitAct);
            console.debug("Min limit act ", newMinLimitAct);
            console.debug("Max limit error ", newMaxLimitError);

            setMaxLimitAct(newMaxLimitAct);
            setMinLimitAct(newMinLimitAct);
            setMaxLimitError(newMaxLimitError);

            setLatestActualDate(startLiveDemoDate);
            setGraphData(data);
        }
        // eslint-disable-next-line
    }, [originData, locale, isPartDayData]);

    useEffect(() => {
        if (originData.length > 0 && holidaysForGraph.length > 0) {
            createGraph();
        }
        // eslint-disable-next-line
    }, [graphData]);

    useEffect(() => {
        if (chart !== undefined) {
            generateColorMap();
            // createGraph();
        }
        // eslint-disable-next-line
    }, [locale]);

    useEffect(() => {

    }, [holidaysForGraph]);

    return (
        <>
            <div className="common-select-style">
                <span className="span-country">{translationsObject[locale].country}</span>

                <Select
                    style={{width: '400px'}}
                    placeholder={tsoPlaceHolder}
                    onChange={onSelectTSO}>
                    {tsoList.map((tso): JSX.Element => (
                        <Select.Option value={tso.id} key={tso.id}>
                            {`${tso.country.name} - ${tso.name}`}
                        </Select.Option>
                    ))}
                </Select>

                <span className="span-language">{translationsObject[locale].download_data_title}</span>

                <Button className="button-part-days" onClick={() => setIsPartDayData(prev => !prev)}>
                    {translationsObject[locale].download_data_button_title[isPartDayData ? 1 : 0]}
                </Button>

                <span className="span-language">{translationsObject[locale].language}</span>

                <Select
                    placeholder={"en"}
                    onChange={changeLocale}
                    value={locale}>
                    <Option value={"en"}>En</Option>
                    <Option value={"de"}>De</Option>
                </Select>
            </div>

            <Spin
                spinning={showGraphSpin}
                size={"large"}>
                <div style={{height: graphHeight}} id={"container"}>
                    <div style={{
                        visibility: showGraphSpin ? "hidden" : "visible",
                        paddingTop: 20,
                        position: "absolute",
                        width: '100%',
                        textAlign: "center",
                        content: '#2a2929',
                        fontSize: 24,
                        backgroundColor: "white",
                        zIndex: 1
                    }}>{translationsObject[locale].graph1_title}</div>

                    <div style={{
                        visibility: showGraphSpin ? "hidden" : "visible",
                        bottom: graphHeight - graph3Region.region.start.y * graphHeight - graph3Region.padding[0] - 20,
                        position: "absolute",
                        width: '100%',
                        textAlign: "center",
                        content: '#2a2929',
                        fontSize: 24
                    }}>{translationsObject[locale].graph2_title}</div>
                </div>
            </Spin>

            <Spin
                spinning={showMapeSpin}
                size={"small"}
                style={{marginTop: 100}}
            >
                <MapeTable
                    selectedTso={selectedTso!}
                    locale={locale}
                    onLoadTable={setShowMapeSpin}
                />
            </Spin>

            <div>
                <SockJsClient
                    url={`${ROOT_URL}/ws`}
                    topics={[`/api/ws/tsos/${selectedTso && selectedTso.id}/load-data`]}
                    onMessage={onIncomeMessage}
                />
            </div>
        </>
    );
};

export default InsightGraph;