import {EnergyData, MapeData, TransferSystemOperation, YearErrors} from "../entity/Entity";
import {fetchMapeData, getDataForGraph, getYearsErrors} from "../api/GraphDataApi";

export const getDataByTso = (
    tso: TransferSystemOperation,
    onSuccess?: (energyDta: EnergyData[]) => void
): void => {
    getDataForGraph(tso.id)
        .then((energyData: EnergyData[]) => {
            if (onSuccess) {
                onSuccess(energyData);
            }
        })
        .catch(() => {
            console.error("Can't loaded energy data.");
        });
};

export const getYearsError = (
    tso: TransferSystemOperation,
    yearsBefore: number,
    onSuccess?: (yearErors: YearErrors[]) => void
): void => {
    getYearsErrors(tso.id, yearsBefore)
        .then((yearsErrors: YearErrors[]) => {
            if (onSuccess) {
                onSuccess(yearsErrors);
            }
        })
        .catch(() => {
            console.error("Can't loaded errors data");
        });
};

export const getMapeData = (
    tso: TransferSystemOperation,
    onSuccess?: (mapeData: MapeData[]) => void
) => {


    fetchMapeData(tso.id)
        .then((mapData: MapeData[]) => {
            if (onSuccess) {
                onSuccess(mapData);
            }
        })
        .catch(() => console.error("Can't loaded MAPE data"));
}