import React from "react";

const trademark = <b>ZONOS™ 24insight</b>;

export const translationsObject = {
    en:{
        mape: 'MAPE',
        mape_last_week: 'last week',
        mape_last_month: 'last month',
        actual_total_load_line: 'Actual total load',
        entsoe_predicate_load_line: 'Forecast (TSO)',
        insight_predicate_total_load_line: 'Forecast (ZONOS™ 24insight)',
        entsoe_error: 'TSO',
        insight_error: 'ZONOS™ 24insight',
        total_load: 'Total Load (MW)',
        time_UTC: 'Time, (UTC)',
        ape_error: 'Forecast error (%)',
        mape_table_title: <>Generic {trademark} algorithm outperforms optimized algorithms of TSOs</>,
        graph1_title: <>LIVE demo of day-ahead forecasting: {trademark} vs. local transmission system operators (TSOs)</>,
        graph2_title: <>{trademark} minimizes the forecast error</>,
        mape_table_row: [
            <>Forecast error ({trademark})</>,
            <>Forecast error (individual TSO)</>
        ],
        forecast_range_title: [
            "LIVE",
            "LIVE forecast"
        ],
        country: "Country",
        language: "Language",
        download_data_title: "Switch to",
        download_data_button_title: [
            "6 days",
            "4 weeks"
        ]
    },
    de:{
        mape: 'MAPE',
        mape_last_week: 'letzte Woche',
        mape_last_month: 'letzter Monat',
        actual_total_load_line: 'Tatsächliche Gesamtlast',
        entsoe_predicate_load_line: 'Vorhersage ÜNB',
        insight_predicate_total_load_line: 'Vorhersage ZONOS™ 24insight',
        entsoe_error: 'ÜNB',
        total_load: 'Gesamtlast (MW)',
        insight_error: 'ZONOS™ 24insight',
        time_UTC: 'Zeit (UTC)',
        ape_error: 'Vorhersagefehler (%)',
        mape_table_title: <>Generischer Algorithmus von {trademark} übertrifft optimierte Algorithmen der ÜNB</>,
        graph1_title: <>LIVE demo der day-ahead Vorhersagen: {trademark} vs. lokale Übertragungsnetzbetreiber (ÜNB)</>,
        graph2_title: <>{trademark} minimiert den Vorhersagefehler</>,
        mape_table_row: [
            "Prognosefehler (ZONOS™ 24insight)",
            "Prognosefehler (lokaler ÜNB)"
        ],
        forecast_range_title: [
            "LIVE",
            "LIVE forecast"
        ],
        country: "Land",
        language: "Sprache",
        download_data_title: "Wechseln zu",
        download_data_button_title: [
            "6 Tage",
            "4 Wochen"
        ]
    }
}