import {TransferSystemOperation} from "../entity/Entity";
import {getTSOs} from "../api/TSOApi";

export const getTSOList = (
    onSuccess?: (tsos: TransferSystemOperation[]) => void
): void => {
    getTSOs().then((tsos: TransferSystemOperation[]) => {
        if (onSuccess) {
            onSuccess(tsos);
        }
    }).catch((e) => {
        console.error("Can't loaded tsos data." + e);
    });
};