import {Country, Holiday} from "../entity/Entity";
import {ROOT_URL} from "./ApiUtil";
import axios, {AxiosResponse} from "axios";

export const getHolidayByCountry = async (country: Country): Promise<Holiday[]> => {
    const url: string = `${ROOT_URL}/holidays/${country.id}`;

    const axiosResponse: AxiosResponse = await axios.get(url);

    return axiosResponse.data.data;
}